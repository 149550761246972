import React from 'react';

import Layout from "../components/layout.js";
import Back from "../components/back.js";
import Hero from "../components/hero.js";

// import AppleAppStoreBageImg from "../images/apple-app-store-badge-outline.svg";

const IndexPage = () => {

  return (
    <Layout>
    	<Back/>
    	<h1><Hero>Nutzungsbedingungen</Hero></h1>

    	<h2>Allgemeine Geschäftsbedingungen</h2>
		<p>Die vorliegenden Allgemeinen Geschäftsbedingungen (nachfolgend AGB), die Informationen und dazugehörigen Regeln über die Nutzung beziehen sich auf die Website ambia.app sowie auf die Apps Ambia (nachfolgend gemeinsam «Ambia»). Sie werden von der im Impressum aufgeführten juristischen Person geführt (nachfolgend «Betreiber»). Die AGB regeln die Geschäftsbeziehungen zwischen den Nutzern von Ambia sowie dem Betreiber.</p>
		<p>Mit Ihrer Nutzung akzeptieren Sie die nachstehenden Bedingungen vorbehaltlos und erklären sich damit einverstanden.</p>
		<p>Die AGB, die Informationen sowie die Regeln über die Nutzung können jederzeit ändern. Die Änderungen sind bindend.</p>
		<p>Ambia schliesst soweit gesetzlich zulässig jegliche Haftung (einschliesslich leichte Fahrlässigkeit und Zufall, ii), Haftung für Mangelfolgeschäden (z.B. entgangener Gewinn) sowie Haftung für Hilfspersonen) und im Zusammenhang mit der Nutzung der App vollständig aus. Dasselbe gilt bei rechtswidrigen Eingriffen auf die Datenübertragung oder -verarbeitung oder das Mobilfunkgerät. Schliesslich übernimmt Ambia keine Haftung für eine korrekte und vollständige Nutzung von Ambia mit Geräten, bei denen die Schutzmassnahmen des Herstellers herabgesetzt oder ausgeschaltet sind.</p>
		<p>Ambia behält sich vor, einzelne Informationen, Spielregeln oder Funktionen abzuändern, grundsätzlich neu zu gestalten oder ohne Vorankündigung zu entfernen. Daher kann Ambia nicht verantwortlich gemacht werden für Folgen durch solche Veränderungen.</p>
		<p>Ambia garantiert nicht, dass die Website und die Apps sowie die angebotenen Funktionalitäten jederzeit störungsfrei benutzt und die abgefragten Informationen reibungslos und fehlerfrei übertragen werden können.</p>
		<p>Ambia kann nicht gewährleisten, dass die hier zur Verfügung gestellten Informationen jederzeit aktuell, detailgenau und vollständig sind.</p>
		<p>Der Zugang zur App Ambia erfolgt über den Download der App Ambia aus dem Apple App Store oder dem Google Play Store.</p>
		<p>Die Benutzung der App in Kombination mit dem Anklicken der Checkbox im Anmeldeprozess gilt als Zustimmung zur Verwendung der übermittelten Daten für weitere Angebote und Informationen (Newsletter und Werbung) und zu deren Übermittlung (per E-Mail), Speicherung und Verarbeitung innerhalb der Schweiz, des Fürstentums Liechtenstein, Deutschland und den USA. Die Weitergabe Ihrer Daten an Dritte ist ausgeschlossen, sofern die Daten bei einem allfälligen Gewinn nicht an den Sponsor zwecks Versand des Gewinnes weitergeleitet werden. Sollten Sie keine Angebote und Informationen von Ambia mehr wünschen, können Sie die Nutzung Ihrer Daten jederzeit auf info@ambia.app widerrufen.</p>
		<p>Ambia verpflichtet sich dazu, im Umgang mit Ihren persönlichen Daten jederzeit die notwendige Sorgfalt walten zu lassen und die schweizerischen Datenschutzbestimmungen einzuhalten.</p>
		<p>Um Fehlerberichte nach einem Absturz der App zu erhalten, werden Fehlertools (u.a. AppCenter von Microsoft aus den Vereinigten Staaten) eingesetzt.</p>
		<p>Um Coupons oder Gutscheine digital einzulösen, müssen diese über die Ambia-App aktiviert werden. Nach der Aktivierung stehen die digitalen Coupons und Gutscheine sofort zur Einlösung bereit und werden nach Ablauf der in der App angezeigten Zeit ungültig.</p>
		<p>Bei limitierten Gewinnen, Coupons und Gutscheinen erfolgt die Abgabe nur, solange der Vorrat reicht.</p>

    </Layout>
    )
}

export default IndexPage
