import React from 'react';

const backStyle = {
  color: "white",
  textDecoration: "none",
}

export default function Back() {

  return (
    <a style={backStyle} href="javascript:history.back()">&larr;</a>
  )
}